import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/styles/animate.css'
import  './assets/styles/reset.css'

import dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs

Vue.prototype.$imgUrl = process.env.VUE_APP_IMG_URL
Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
