<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
  @font-face {
      font-family: "yunong";
      src: url('@/assets/fonts/Alibaba_PuHuiTi_2.0_45_Light_45_Light.ttf');
      font-weight: normal;
      font-style: normal;
  }
  
  body {
    background: #fff;
    font-family: 'yunong';
  }
  #app{
    user-select: none;
  }
  ._content{
    width: 1200px;
    margin: 0 auto;
  }
  .el-backtop{
    width: 62px !important;
    height: 62px !important;
  }
  .backup-icon{
    width: 62px;
    height: 62px;
    background: url('https://hwysource.yunongyun.com/gwang/img/backup.png');
    background-size: 100% 100%;
    &:hover{
      background: url('https://hwysource.yunongyun.com/gwang/img/backup-hover.png');
      background-size: 100% 100%;
    }
  }
  /* 自定义滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  /* 自定义滚动条的滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 2px;
  }
  
  /* 设置滚动条的宽度 */
  ::-webkit-scrollbar {
    width: 6px;
  }
</style>
