import Vue from 'vue'
import ElementUI from 'element-ui'
import '@/assets/styles/element-variables.scss'
// import 'element-ui/lib/theme-chalk/index.css'

// 设置element-ui部分组件默认属性
ElementUI.Select.props.filterable = {default: true, type: Boolean}
ElementUI.Select.props.clearable = {default: true, type: Boolean}
ElementUI.Input.props.clearable = {default: true, type: Boolean}
ElementUI.Pagination.props.layout = {default: 'total,sizes, prev, pager, next, jumper', type: String}
ElementUI.Pagination.props.background = {default: true, type: Boolean}
ElementUI.Table.props.stripe = {default: true, type: Boolean}
ElementUI.Table.props.border = {default: true, type: Boolean}
ElementUI.TableColumn.props.align = {default: 'center', type: String}
ElementUI.Dialog.props.closeOnClickModal = {default: false, type: Boolean}
ElementUI.Form.props.labelWidth = {default: 'auto', type: String}
ElementUI.Form.props.labelPosition = {default: 'left', type: String}
ElementUI.Alert.props.showIcon = {default: true, type: Boolean}
ElementUI.Alert.props.closable = {default: false, type: Boolean}
ElementUI.Cascader.props.clearable = {default: true, type: Boolean}
ElementUI.Cascader.props.filterable = {default: true, type: Boolean}

Vue.use(ElementUI, {
    size: "small"
})
